import React, { useState, useEffect } from 'react';
import './App.css';
import LoginPage from './LoginPage';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

function TransactionPage() {
  const [rows, setRows] = useState([]);
  const handleLogin = async ({ username, password }) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    localStorage.setItem('token', data.accessToken);
    console.log(response.headers);
    window.location.reload();
  };

  const handleDownloadFile = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER}/transaction/download-excel?transaction_id=`+id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const disposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded-file.xlsx'; // Default filename

    if (disposition && disposition.includes('filename=')) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, ''); // Clean up the extracted filename
      }
    }

    const blob = await response.blob();

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the desired file name

    // Append the anchor to the body
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    window.URL.revokeObjectURL(url);
  };

  const handleEdit = (id) => {
    window.location.href = '/transaction-list?transaction_id=' + id;
}

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/transaction`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
            }
          });
        const data = await response.json();
        setRows(data);
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
      }
    };

    fetchTransactions();
  }, []);



  const token = localStorage.getItem('token');
  if (!token) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <div>
      <div className="center-horizontal mt-5 mb-5 table-container">
      <table className="table table-striped">
        <thead className='thead-light sticky-top'>
          <tr>
            <th className='text-center'>No</th>
            <th className='text-center'>Date</th>
            <th className='text-center'>Sudah Final?</th>
            <th className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td className='text-center'>{index + 1}</td>
              <td className='text-center'>{row.date}</td>
              <td className='text-center'>{row.is_final ? "Ya" : "Tidak"}</td>
              <td className='text-center'>
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                style={{ marginRight: '10px' }} // Add right margin to the first button
                onClick={() => handleDownloadFile(row.id)}
                >
                Print
                </Button>

                <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() => handleEdit(row.id)}
                >
                Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        </table>
    
    </div>  
    </div>
  );
}

export default TransactionPage;