
import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { debounce } from 'lodash';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const [partNumber, setPartNumber] = useState('');
  const [lotNo, setLotNo] = useState('');
  const [quantity, setQuantity] = useState('');
  const [dateCode, setDateCode] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [barcodeContent, setBarcodeContent] = useState('');
  const [formVisible, setFormVisible] = useState(false); 
  const [singleInputValue, setSingleInputValue] = useState('');
  const [partNumberDisabled, setPartNumberDisabled] = useState(false);
  const [quantityDisabled, setQuantityDisabled] = useState(false);
  const [lotNoDisabled, setLotNoDisabled] = useState(false);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const debouncedAlert = useCallback(
    debounce((input) => {
      setBarcodeContent(input);
      const parsedYageo = parseScannedStr(input);
      setPartNumber(parsedYageo.mpn);
      setQuantity(parsedYageo.qty);
      setLotNo(parsedYageo.bn);
      setLotNoDisabled(true);
      setPartNumberDisabled(true);
      setQuantityDisabled(true);

      setFormVisible(true);
    }, 500),
    [] // dependencies array is empty which means the function will not be recreated unless the component is unmounted and mounted again
  );

  const handleChangeBarcode = (event) => { 
    const input = event.target.value;
    setSingleInputValue(input); // Update the state immediately
  
    // Debounce the rest of the logic
    debouncedAlert(input);
  };

  const handleChangeBarcodeDebounce = useCallback(debounce(handleChangeBarcode, 500), []);

  // const showAlert = useCallback(debounce((value) => {
  //   if (value && value.length > 0) {
  //       setInputValue('');

  //       alert(parseScannedStr(value));
  //   }
  // }, 500), []);

  function parseScannedStr(str) {
    if (str === '' || str === null) {
      return;
    }

    let splitValues = str.split(String.fromCharCode(10));
    // split value 1 = MPN
    let mpnStr = splitValues[0];
    let splitMpn = mpnStr.split("1P");
    let mpn = splitMpn[1];
    //split value 2 = B/N
    let bnStr = splitValues[1];
    let splitBn = bnStr.split("1T");
    let bn = splitBn[1];

    //split value 3 = QTY
    let qtyStr = splitValues[2];
    let splitQty = qtyStr.split("Q");
    let qty = splitQty[1];
    
    return {
        mpn: mpn,
        bn: bn,
        qty: parseInt(qty)
    };
  }


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    console.log(e);
      if (e.detail === 0) {
        e.preventDefault();
      }
      else {
        e.preventDefault();
    
      const response = await fetch(`${process.env.REACT_APP_SERVER}/transaction-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify({
          transaction_id: "1",
          product_code: partNumber,
          quantity: quantity,
          lot_number: lotNo,
          date_code: dateCode
      }),
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const data = await response.json();
    
      // Do something with the data
      console.log(data);
    
      handleClose();
      // reload the page
      window.location.reload();
      }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Enter Part Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Part Details
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="dropdown-label">Pilih nama Brand</InputLabel>
            <Select
              labelId="dropdown-label"
              value={selectedValue}
              onChange={handleChange}
              label="Select an Option"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="YAGEO_QR">YAGEO (QR Code)</MenuItem>
              <MenuItem value="YAGEO_MANUAL">YAGEO (Manual)</MenuItem>
              <MenuItem value="GSK_SIN">GSK SIN Manual</MenuItem>
            </Select>
          </FormControl>
          
          {selectedValue === "GSK_SIN" && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="partNumber"
                label="Part Number"
                value={partNumber}
                onChange={(e) => setPartNumber(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="quantity"
                label="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                id="lotNo"
                label="Lot No"
                value={lotNo}
                onChange={(e) => setLotNo(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                id="dateCode"
                label="Date Code"
                value={dateCode}
                onChange={(e) => setDateCode(e.target.value)}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Submit
              </Button>
            </>
          )}

          {/* Render single input for YAGEO_MANUAL */}
          {selectedValue === 'YAGEO_QR' && !formVisible && (
            <TextField
              margin="normal"
              multiline
              fullWidth
              id="manualInput"
              label="Enter Data (e.g., PN123,5,LOT001,2024-01-01)"
              value={singleInputValue}
              onChange={handleChangeBarcode}
            />
          )}

          {/* Render the form fields only after the single input is filled */}
          {selectedValue === 'YAGEO_QR' && formVisible && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="partNumber"
                label="Part Number"
                value={partNumber}
                disabled={partNumberDisabled}
                onChange={(e) => setPartNumber(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="quantity"
                label="Quantity"
                type="number"
                value={quantity}
                disabled={quantityDisabled}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                id="lotNo"
                label="Lot No"
                value={lotNo}
                disabled={lotNoDisabled}
                onChange={(e) => setLotNo(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                id="dateCode"
                label="Date Code"
                value={dateCode}
                onChange={(e) => setDateCode(e.target.value)}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Submit
              </Button>
            </>
          )}          
          <Button onClick={handleClose} fullWidth variant="outlined">
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};