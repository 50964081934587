import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const DataTable = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_SERVER}/transaction-list?transaction_id=1`, {
      headers: {
        'Authorization': `${token}`
      }
    })
    .then(response => response.json())
    .then(data => setRows(data))
    .catch(error => console.error('Error:', error));
  }, []);

  const handleDelete = (indexToDelete) => {
    // Filter out the row with the specified index
    const response = fetch(`${process.env.REACT_APP_SERVER}/transaction-list/` + indexToDelete, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }});

    // reload the page
    window.location.reload();
  };

  return (
    <div className="center-horizontal mt-5 mb-5 table-container">
      <table className="table table-striped">
        <thead className='thead-light sticky-top'>
          <tr>
            <th className='text-center'>No</th>
            <th className='text-center'>MPN</th>
            <th className='text-center'>Lot No</th>
            <th className='text-center'>Date Code</th>
            <th className='text-center'>Qty</th>
            <th className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td className='text-center'>{index + 1}</td>
              <td className='text-center'>{row.product_code}</td>
              <td className='text-center'>{row.lot_number}</td>
              <td className='text-center'>{row.date_code}</td>
              <td className='text-right'>{row.quantity.toLocaleString()}</td>
              <td className='text-center'>
                {/* Delete button */}
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(row.id)}
                >Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" className="total-row">Total Qty</td>
            <td className='text-right'>{rows.reduce((total, row) => total + row.quantity, 0).toLocaleString()}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DataTable;