import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TransactionPage from './TransactionPage';
import TransactionList from './TransactionList'; // Import your TransactionList component


function App() {
  return (
    <Router>
      <div className="container">
        <div className="center-horizontal">
          <h1>Barcode App</h1>
        </div>

        <div className="center-horizontal mt-5">
          <Routes>
            <Route path="/transaction-list" element={<TransactionList />} />
            <Route path="/" element={<TransactionPage />} />
          </Routes>
        </div>
      </div>
      </Router>
  );
}

export default App;