import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import './App.css';
import BasicModal from './BasicModal';
import DataTable from './DataTable';
import LoginPage from './LoginPage';

function TransactionList() {
    const handleLogin = async ({ username, password }) => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
    
        localStorage.setItem('token', data.accessToken);
        console.log(response.headers);
        window.location.reload();
      };

  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState([]);

  const showAlert = useCallback(debounce((value) => {
    if (value && value.length > 0) {
        setInputValue('');

        rows.push(parseScannedStr(value));
        setRows(rows);
    }
  }, 500), []);

  function parseScannedStr(str) {
    let splitValues = str.split(String.fromCharCode(10));
    // split value 1 = MPN
    let mpnStr = splitValues[0];
    let splitMpn = mpnStr.split("1P");
    let mpn = splitMpn[1];
    //split value 2 = B/N
    let bnStr = splitValues[1];
    let splitBn = bnStr.split("1T");
    let bn = splitBn[1];

    //split value 3 = QTY
    let qtyStr = splitValues[2];
    let splitQty = qtyStr.split("Q");
    let qty = splitQty[1];
    
    return {
        mpn: mpn,
        bn: bn,
        qty: parseInt(qty)
    };
  }

  const handleDownloadFile = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER}/transaction/download-excel?transaction_id=1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const disposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded-file.xlsx'; // Default filename

    if (disposition && disposition.includes('filename=')) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, ''); // Clean up the extracted filename
      }
    }

    const blob = await response.blob();

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the desired file name

    // Append the anchor to the body
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    showAlert(inputValue);
  }, [inputValue, showAlert]);


  const token = localStorage.getItem('token');
  if (!token) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <div className="container">
      <div className="center-horizontal">
        <h1>Barcode App</h1>
      </div>

    <div className="center-horizontal mt-5">
        <BasicModal />
    </div>
    <DataTable rows={rows} />

    {/* make a download finalized button, add modal to confirm download, than hit api download */}
    <div className="center-horizontal mt-5">
        <button className="btn btn-primary" onClick={handleDownloadFile}>Download Finalized</button>
    </div>
    </div>
  );
}

export default TransactionList;